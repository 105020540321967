.meeting.wrapper {
  background: #F7E7D0;
  height: 100%;
}

video {
  max-width: unset;
}

.meeting .ag-header {
  padding: 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ag-header-lead {
  font-size: 16px;
  line-height: 35px;
}

.meeting .ag-footer {
  padding: 0 30px;
  display: flex;
  align-items: center;
}

.meeting .ag-main {
  position: relative;
}

.meeting .ag-footer :first-child {
  margin-right: 80px;
}

.header-logo {
  width: 60px;
  height: 35px;
  margin-right: 12px;
}

.ag-container {
  width: calc(100% - 60px);
  height: 100%;
  margin: 0 auto;
  background: rgba(255, 255, 255, .1);
  border-radius: 12px;
}

.ag-header-switch{
  font-size: 50px;
}

.chat-screen-main{
  position: fixed;
  right: 0;
  width: 250px;
  box-shadow: 0 5px 30px rgb(32 32 32 / 25%);
  height: 100vh;
  /* border-radius: 10px; */
  z-index: 1000;
  top: 0;
  /* border: 3px solid black; */
  background: white;
  transition-timing-function: linear;
  transition: 1s;

}
.chat-window .title{
  padding: 10px;
  background: #f7e6d0;
}
.chat-window p span{
  float: right;
  cursor: pointer;
}
.volumesec{
  width: 120px;
  position: absolute;
  bottom: 48px;
  padding: 10px;
  z-index: 2000;
  right: 35%;
  margin: 0; 
 }
 .volumesec .rc-slider-track{
   background: #000!important;
  box-shadow: 0 5px 30px rgb(32 32 32 / 25%);
 }
 .volumesec .rc-slider-handle{
   /* border: 2px solid #ff884d ; */
   background: #F7E7D0;
 }
 
 .volumesec .rc-slider-handle:hover{
  /* border: 2px solid #000 ; */
  background: #fff;
}

@media only screen and (min-width: 550px) and (max-width: 1030px) {
  .userwait #ag-canvas{
    width: 45%!important;
  }
  .session-screen-wrapper{
    top:30vh!important;
  }
}
@media only screen and (min-width: 350px) and (max-width: 500px) {
  .userwait #ag-canvas{

  width: 100%!important;
}
  .chat-screen-main{
    width: 100%;
  }
  .userwait .session-screen-wrapper img.logo{
    max-width: 250px;
    width:100%
  }
  .userwait .session-screen-wrapper {
    top:35%!important

}
.cameracontrol{
  display:block;
}

section span { position: absolute;
 
  left: 20%!important;
  font-size: 12px;
}

section span:first-child {
  margin-left: 30px!important    

   } 
   section span .fa{
     font-size: 15px!important;
   }
   .fa.fa-video-camera.slash:after{
     top:-5px!important;
     height: 25px!important;
   }
}
#ag-canvas .audiolistsec{
  position: absolute;
  bottom: 0;
  z-index: 100;
  right: 10%;
}


.userwait .ag-container{
z-index: 1000;
height: 80%;;
 
}
.userwait .ag-main{
  justify-content: unset;
}
.userwait #ag-canvas{
  width: 20%;
  margin-left:auto;
  height:40%;
}
.chat-body{
  max-height: 78vh;
  overflow-y: auto;

}
section span .fa{
    font-size: 25px;
    color: #f7e6d0;
}
section{
  position: relative;
}
section span { position: absolute;
  bottom: 5px;
   z-index: 10000;
  left: 34%;
}

section span:first-child {
  margin-left: 50px    

   } 
   
    .fa.fa-video-camera.slash:after {
        z-index: 1000;
        position: absolute;
        content: "";
        right: 35%;
        top: -5px;
        width: 3px;
        height: 35px;
        display: block;
        background: white;
        transform: rotate(36deg);
        border-right: 1px solid transparent;
}
.videomuteText,.videomuteTextsmall{
  position: absolute;
  top:50%;
  color: #fff;
  display: block;
  text-align: center;
  margin-left: 0!important;
  left:0;
  width: 100%;
}
.userwait .videomuteText,.userwait .videomuteTextsmall{
  left:0!important;
 font-size: 11px;   
display: block;
text-align: center;
margin-left: 0!important;
width: 100%;
}