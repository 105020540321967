#ag-canvas {
  height: 100%;
}

.ag-item :first-child {
  border-radius: 6px;
}
@media only screen and (max-width: 700px) {
  .ag-btn-group{
    bottom: -63px;
    left: 27%;
    width: 100%;
  }
}

.ag-item {
  border-radius: 6px;
  border: 2px #F7E7D0 solid;
  background: url('../../assets/images/avatar.png') center no-repeat;
  height: 100%;
  width: 100%;
}

#ag-resize-container {
  background-image: none!important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none!important;
} 


/* button group */

.ag-btn-group {    height: 70px;
  width: 50%;
  text-align: center;
  margin: auto;
  position: absolute;
  bottom: -69px;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 27%;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background:  #F7E7D0;
}
.big-video video{
  object-fit: fill!important;
}
.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  height: 50px;
  width: 50px;
  background: #000;
  border-radius: 50%;
  box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  font-size: 60px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #F7E7D0;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #F7E7D0;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
.btn_sec{
  position: absolute;
    bottom: 10px;
    display: flex;
  width: 100%;
  padding: 0px 5px;
  align-items: center;

}
.btn_sec .send-btn{
  padding: 8px;
  background-color: #ff6f00;
  border-radius: 50%;
  color: #fff;
  margin-left: 2px;
  max-height: 45px; 

}
.ReceiveMsg, .sendsms{
  width: auto;
  /* border-radius: 15px;
  border-bottom-right-radius: 0px;
  margin-bottom: 15px;*/
 
  line-height: 25px;
  display: inline;
}
.chattext.getsms{
  background: #e4e9ef;
}
.chattext{
  width: auto;
    border-radius: 15px;
    border-bottom-right-radius: 0px;
    margin-bottom: 15px;
    background: #ffefe6; 

    padding: 0.5rem 1rem;
}
/* *{
  font-family: sans-serif;
}
h1,h4{
  text-align: center;
}
#local{
  position: absolute;
  width: 480px;
  height: 360px;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
#local video{
  position: relative !important;
}
#remote{
  position: absolute;
  width: 480px;
  height: 360px;
  top: 40px;
  right: 5px;
}
#remote video{
  height: auto;
  position: relative !important;
} */


*{
  font-family: sans-serif;
}
h1,h4{
  text-align: center;
}
#me{
  position: relative;
  width: 50%;
  margin: 0 auto;
  display: block;
}
#me video{
  position: relative !important;
}
#remote-container{
  display: flex;
}
#remote-container video{
  height: auto;
  position: relative !important;
}
.chat-body{
  position: relative;
}
.customload{
  height: 100%;
    width: 250px;
    right: 0;
    background: #fff;
    z-index: 100;
    margin-left: auto;
    left: 0;
    display: block;
    position: fixed;
    opacity: 0.9;
    top: 70px;
  
}
.customload .spinner-border{
  position: absolute;
  top: 40%;
  border: 0.4em solid #ff6f00;
  border-right-color:transparent;
  left: 45%;
}

@media only screen and (max-width: 600px) {
  .customload{
    width: 100%;

  }
}
.session-screen-wrapper .btn-primary{
  background: #000000;
  font-size: 18px;
  padding: 5px 20px;
  border:none;
  z-index:222222222
}
.session-screen-wrapper .btn-primary:active,.session-screen-wrapper .btn-primary:hover{
/* border:1px solid #000; */
background: #ff6f00;
color: #fff;
}
.btn-primary.disabled, .btn-primary:disabled:hover{
cursor: not-allowed;
}