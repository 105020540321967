/* 

dark cyan #163347;
light blue #00B6ED;
green #7ED321;
pink #E3B6FF;
yellow #F8E71C;
red #D0021B;
dark blue #124A63;
grey #637C8E
*/

html,
body,
#root,
.full {
  height: 100%;
  min-height: 100%;
  font-size: 14px;
  overflow: auto;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

.ag-header,
.ag-footer {
  /* flex-basis: 70px; */
  width: 100%;
  opacity: .8;
  height: 70px;
  color: #FFF;
}

.ag-main {
  width: 100%;
  height: calc(100% - 140px);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.ag-rounded {
  border-radius: 19px!important;
}

.ag-href {
  color: #FFF;
  cursor: pointer;
}

.ag-href:hover {
  color: #FFF;
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background: #F7E7D0; font-family: 'Arvo', serif;height: 100vh;
}

.page_404  img{ width:100%;
    
}

.four_zero_four_bg{
 
 /* background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif); */
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: rgb(255 136 77)!important;
  background: #fff;
  border-radius: 10px;
  font-size: 18px;
    padding: 10px 20px;
    border: 1px solid rgb(255 136 77);
    text-decoration: none;
    margin: 20px 0;
    display: inline-block;}
    .link_404:hover{	
      background: rgb(255 136 77)!important;
      border: 1px solid #fff;

      color: #fff!important;text-decoration: none;
    }
    
    
 
@media only screen and (max-width: 700px) {
  .four_zero_four_bg{
  height: 140px;   
  }
}